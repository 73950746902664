<template>
    <div class="footer footer-main bg-black py-5">
        <div class="text-light text-center container-xl d-flex flex-column flex-lg-row justify-content-around align-items-center">
            <p class="mb-4 mb-lg-0 text-white">© 2022 A4C Marketing Kft. - Minden jog fenntartva.</p>
            <a 
                href="/pdf/adatkezelesi_tajekoztato.pdf" 
                target="_blank" 
                class="mb-4 mb-lg-0 text-decoration-underline"
            >
                Adatkezelési tájékoztató.
            </a>
            <a class="text-decoration-underline" :href="'mailto:'+$api_conf.emailAddress">{{ $api_conf.emailAddress }}</a>
            <a href="#" v-scroll-to="'#hero'" class="scroll-up mt-4 mt-md-0"></a>
        </div>        
        <div>            
            <CookieBannerComp 
            class="w-100  d-flex align-items-center justify-content-center justify-content-md-start mb-0"/>            
        </div>
    </div>
</template>


<script>
import CookieBannerComp from '@/plugins/cookiebanner/CookieBannerComp.vue'

export default {
    components: {
        CookieBannerComp        
    }
}
</script>